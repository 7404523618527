import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "./StrictModeDroppable"; // Import the custom component
import { db, auth } from "../../firebase";
import { collection, addDoc, doc, getDocs, query, where, updateDoc, deleteDoc } from "firebase/firestore/lite";
import { LanguageContext } from "../../context/LanguageContext";
import lang from "../../components/translations";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "palegreen" : "",
  ...draggableStyle,
});

const Education = ({ nextStep, prevStep, educationHistory, handleDataChange }) => {
  const [newEntry, setNewEntry] = useState({
    name: "",
    graduationYear: "",
    certificateLevel: "",
    major: "",
    gradeGPA: "",
    country: "",
    languageOfStudy: "",
  });

  const { appLanguage } = useContext(LanguageContext);

  const [errors, setErrors] = useState({});
  const [isAddUpdateBtnDisabled, setIsAddUpdateBtnDisabled] = useState(true);
  const [hasEducationHistory, setHasEducationHistory] = useState(true);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeOutForCancelForm, setFadeOutForCancelForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState(null); // Store the original data

  useEffect(() => {
    if (!originalData && educationHistory) {
      setOriginalData({ ...educationHistory }); // Store the original data on mount
    }
  }, [educationHistory, originalData]);

  const handleCheckboxChange = (event) => {
    setHasEducationHistory(event.target.checked);

    if (!event.target.checked) {
      setFadeOut(true);
      setTimeout(() => {
        setHasEducationHistory(false);
        setFadeOut(false);
        setShowForm(false);
      }, 300);
    } else {
      setHasEducationHistory(true);
    }
  };

  const handleCheckboxOnClick = (event) => {
    if (!event.target.checked && educationHistory.length > 0) {
      event.target.checked = true;
      showToast(lang[appLanguage].warnWaitTitle, lang[appLanguage].warnRmvAllHstry);
    }

    if (event.target.checked && educationHistory.length === 0) {
      setShowForm(true);
      setIsAddUpdateBtnDisabled(false);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    // Reorder the items in the education history
    const reorderedItems = reorder(educationHistory, result.source.index, result.destination.index);

    // Use handleDataChange to update the reordered items in the parent (Wizard) component
    handleDataChange(reorderedItems, "educationHistory");
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();
    setIsAddUpdateBtnDisabled(true);

    if (validate()) {
      try {
        let updatedEducationHistory;
        if (editItemId) {
          // Update existing entry
          updatedEducationHistory = educationHistory.map((item) =>
            item.eduId === editItemId ? { ...item, ...newEntry } : item
          );
        } else {
          // Add new entry
          updatedEducationHistory = [...educationHistory, { ...newEntry, eduId: Date.now().toString() }];
        }

        // Pass the updated history back to Wizard
        handleDataChange(updatedEducationHistory, "educationHistory");

        setShowForm(false);
        setEditItemId(null);
        setNewEntry({
          name: "",
          graduationYear: "",
          certificateLevel: "",
          major: "",
          gradeGPA: "",
          country: "",
          languageOfStudy: "",
        });
      } catch (error) {
        console.error("Error adding/updating education history: ", error);
      } finally {
        setIsAddUpdateBtnDisabled(false);
      }
    } else {
      setIsAddUpdateBtnDisabled(false);
    }
  };

  const handleEdit = (item) => {
    setNewEntry(item);
    setEditItemId(item.eduId);
    setShowForm(true);
  };

  const handleDelete = (eduId) => {
    const updatedEducationHistory = educationHistory.filter((item) => item.eduId !== eduId);
    handleDataChange(updatedEducationHistory, "educationHistory");
  };

  const handleCancel = () => {
    setFadeOutForCancelForm(true);
    setTimeout(() => {
      setShowForm(false);
      setFadeOutForCancelForm(false);
    }, 300);

    setEditItemId(null);
    setNewEntry({
      name: "",
      graduationYear: "",
      certificateLevel: "",
      major: "",
      gradeGPA: "",
      country: "",
      languageOfStudy: "",
    });
  };

  const validate = () => {
    let tempErrors = {};
    let valid = true;

    if (!newEntry.name || newEntry.name === "") {
      tempErrors.name = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.certificateLevel) {
      tempErrors.certificateLevel = lang[appLanguage].eduValCertLvl;
      valid = false;
    }

    if (!newEntry.graduationYear) {
      tempErrors.graduationYear = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (
      parseInt(newEntry.graduationYear, 10) > new Date().getFullYear() ||
      parseInt(newEntry.graduationYear, 10) < 1900
    ) {
      tempErrors.graduationYear = lang[appLanguage].eduValGradYear + new Date().getFullYear();
      valid = false;
    }

    if (!newEntry.major || newEntry.major === "") {
      tempErrors.major = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (newEntry.gradeGPA < 1 || newEntry.gradeGPA > 100) {
      tempErrors.gradeGPA = lang[appLanguage].eduValGPA1;
      valid = false;
    }

    if (!newEntry.gradeGPA || newEntry.gradeGPA === "" || newEntry.gradeGPA === 0) {
      tempErrors.gradeGPA = lang[appLanguage].eduValGPA2;
      valid = false;
    }

    if (!newEntry.country || newEntry.country === "") {
      tempErrors.country = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.languageOfStudy || newEntry.languageOfStudy === "") {
      tempErrors.languageOfStudy = lang[appLanguage].valBlankField;
      valid = false;
    }
    setErrors(tempErrors);

    if (!valid) {
      setIsAddUpdateBtnDisabled(true);
    }

    return valid;
  };

  useEffect(() => {
    const form = document.querySelector(".needs-validation");
    if (form) {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
          }
          form.classList.add("was-validated");
        },
        false
      );
    }
  }, [showForm]);

  useEffect(() => {
    educationHistory.length === 0 ? setIsCheckboxDisabled(false) : setIsCheckboxDisabled(true);
  }, [educationHistory]);

  const handleSaveAndContinue = async () => {
    const hasChanged = JSON.stringify(originalData) !== JSON.stringify(educationHistory);

    if (!hasChanged) {
      nextStep();
      return;
    }

    try {
      const user = auth.currentUser;
      const userEmail = user.email;

      // Query Firebase for the user's personalInfo document
      const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
      const querySnapshot = await getDocs(personalInfoQuery);

      if (!querySnapshot.empty) {
        const personalInfoDoc = querySnapshot.docs[0];

        // Fetch current education history from Firebase to compare with local state
        const educationSnapshot = await getDocs(collection(db, "personalInfo", personalInfoDoc.id, "educationHistory"));
        const firebaseEducationIds = educationSnapshot.docs.map((doc) => doc.id); // Get all current Firebase document IDs

        // Track local document IDs
        const localEducationIds = educationHistory.map((entry) => entry.firebaseDocId).filter(Boolean);

        // Delete any documents in Firebase that are not in the local educationHistory array
        for (const firebaseDocId of firebaseEducationIds) {
          if (!localEducationIds.includes(firebaseDocId)) {
            const docRef = doc(db, "personalInfo", personalInfoDoc.id, "educationHistory", firebaseDocId);
            await deleteDoc(docRef); // Delete from Firebase
          }
        }

        // Now, update or add documents to Firebase
        for (const entry of educationHistory) {
          if (entry.firebaseDocId) {
            // If the document ID exists locally, update it in Firestore
            const educationDocRef = doc(
              db,
              "personalInfo",
              personalInfoDoc.id,
              "educationHistory",
              entry.firebaseDocId
            );
            await updateDoc(educationDocRef, {
              name: entry.name,
              graduationYear: entry.graduationYear,
              certificateLevel: entry.certificateLevel,
              major: entry.major,
              gradeGPA: entry.gradeGPA,
              country: entry.country,
              languageOfStudy: entry.languageOfStudy,
              // Do NOT save firebaseDocId in Firestore
            });
          } else {
            // If there's no firebaseDocId, add a new document
            const newDocRef = await addDoc(collection(db, "personalInfo", personalInfoDoc.id, "educationHistory"), {
              name: entry.name,
              graduationYear: entry.graduationYear,
              certificateLevel: entry.certificateLevel,
              major: entry.major,
              gradeGPA: entry.gradeGPA,
              country: entry.country,
              languageOfStudy: entry.languageOfStudy,
              // Do NOT save firebaseDocId in Firestore
            });

            // Attach the generated Firestore ID to the local entry (but not to Firestore)
            entry.firebaseDocId = newDocRef.id;
          }
        }

        setLoading(false);

        // After saving to Firebase, move to the next step
        nextStep();
      } else {
        console.error("No personalInfo document found for the user");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error saving education data to Firebase before continuing:", error);
      setLoading(false);
    }
  };

  const showToast = (header, message) => {
    const toastElement = document.getElementById("liveToast");
    const toastHeader = toastElement.querySelector(".me-auto");
    const toastBody = toastElement.querySelector(".toast-body");
    toastHeader.textContent = header;
    toastBody.textContent = message;
    const toast = new window.bootstrap.Toast(toastElement, {
      delay: 5000,
    });
    toast.show();
  };

  return (
    <div className="container">
      <p className="text-muted">{lang[appLanguage].eduDesc1}</p>
      <hr />
      <div className="mb-4 mx-0 navbar rounded-2 row shadow">
        <div className="col-12 col-md-6">
          <h4>
            <i className="bi bi-backpack-fill me-2"></i> {lang[appLanguage].eduTitle}
          </h4>
        </div>
        {(hasEducationHistory || fadeOut) && (
          <div className={`col-12 col-md-6 ${fadeOut ? "fade-out" : "fade-in"}`}>
            <button
              type="button"
              className="btn btn-light float-end"
              onClick={() => {
                setShowForm(true);
                setIsAddUpdateBtnDisabled(false);
              }}
              disabled={showForm}
            >
              <i className="bi bi-plus-circle me-1"></i>
              {lang[appLanguage].btnAddEduHistry}
            </button>
          </div>
        )}
      </div>

      <div className="row p-2">
        <div className="col-auto">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="hasEducationHistory"
              checked={hasEducationHistory}
              onChange={handleCheckboxChange}
              disabled={isCheckboxDisabled}
              onClick={handleCheckboxOnClick}
            />{" "}
            <label className="form-check-label" htmlFor="hasEducationHistory">
              {lang[appLanguage].eduHaveEduHistry}
            </label>
          </div>
        </div>
      </div>

      {(showForm || (fadeOut && showForm) || fadeOutForCancelForm) && (
        <div className={`row justify-content-md-center ${fadeOut || fadeOutForCancelForm ? "fade-out" : "fade-in"}`}>
          <form onSubmit={handleAddOrUpdate} className="needs-validation" noValidate>
            <div className="col">
              <div className="card m-3 mx-auto rounded-3 shadow">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduCertLvl}</label>
                        <select
                          className={`form-select ${errors.certificateLevel ? "is-invalid" : ""}`}
                          required
                          value={newEntry.certificateLevel}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              certificateLevel: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            {lang[appLanguage].selectOpt}
                          </option>
                          <option value="High school">{lang[appLanguage].eduCrtLvlHS}</option>
                          <option value="Diploma">{lang[appLanguage].eduCrtLvlDip}</option>
                          <option value="Bachelor's">{lang[appLanguage].eduCrtLvlBsc}</option>
                          <option value="Master">{lang[appLanguage].eduCrtLvlMst}</option>
                          <option value="PhD">{lang[appLanguage].eduCrtLvlPhD}</option>
                        </select>
                        <div className="invalid-feedback">{errors.certificateLevel}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduName}</label>
                        <input
                          type="text"
                          maxLength={100}
                          className={`form-control ${errors.name ? "is-invalid" : ""}`}
                          value={newEntry.name}
                          required
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              name: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">{errors.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduGradYear}</label>
                        <input
                          type="number"
                          className={`form-control ${errors.graduationYear ? "is-invalid" : ""}`}
                          value={newEntry.graduationYear}
                          required
                          minLength={4}
                          maxLength={4}
                          min={1900}
                          max={new Date().getFullYear()}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              graduationYear: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">{errors.graduationYear}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduMajor}</label>
                        <input
                          type="text"
                          required
                          maxLength={100}
                          className={`form-control ${errors.major ? "is-invalid" : ""}`}
                          value={newEntry.major}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              major: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">{errors.major}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduGPA}</label>
                        <input
                          type="number"
                          required
                          min={0}
                          max={100}
                          step={0.01}
                          className={`form-control ${errors.gradeGPA ? "is-invalid" : ""}`}
                          value={newEntry.gradeGPA}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              gradeGPA: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">{errors.gradeGPA}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduCountry}</label>
                        <input
                          type="text"
                          required
                          maxLength={100}
                          className={`form-control ${errors.country ? "is-invalid" : ""}`}
                          value={newEntry.country}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              country: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">{errors.country}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].eduStdyLang}</label>
                        <input
                          type="text"
                          required
                          maxLength={50}
                          className={`form-control ${errors.languageOfStudy ? "is-invalid" : ""}`}
                          value={newEntry.languageOfStudy}
                          onChange={(e) =>
                            setNewEntry({
                              ...newEntry,
                              languageOfStudy: e.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">{errors.languageOfStudy}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="">
                      <button type="submit" className="btn btn-primary" disabled={isAddUpdateBtnDisabled}>
                        {isAddUpdateBtnDisabled ? (
                          <div className="spinner-border text-light" role="status">
                            <span className="status px-1"> {lang[appLanguage].loading}</span>
                          </div>
                        ) : editItemId ? (
                          lang[appLanguage].btnUpdate
                        ) : (
                          lang[appLanguage].btnAdd
                        )}{" "}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={() => {
                          handleCancel();
                          setFadeOutForCancelForm(true);
                        }}
                      >
                        {lang[appLanguage].btnCancel}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {(hasEducationHistory || fadeOut) && (
        <form className={`fade-in ${fadeOut ? "fade-out" : "fade-in"}`}>
          <div className="row">
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId="educationHistory">
                {(provided, snapshot) => (
                  <div className="col" {...provided.droppableProps} ref={provided.innerRef}>
                    {educationHistory.map((item, index) => (
                      <Draggable key={item.eduId} draggableId={item.eduId} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className="card m-3 mx-auto rounded-3 shadow"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div className="card-header fw-medium">
                              {item.name}
                              &nbsp;-&nbsp;
                              <span className="text-body-secondary">
                                {item.certificateLevel === "High school"
                                  ? lang[appLanguage].eduCrtLvlHS
                                  : item.certificateLevel === "Diploma"
                                    ? lang[appLanguage].eduCrtLvlDip
                                    : item.certificateLevel === "Bachelor's"
                                      ? lang[appLanguage].eduCrtLvlBsc
                                      : item.certificateLevel === "Master"
                                        ? lang[appLanguage].eduCrtLvlMst
                                        : lang[appLanguage].eduCrtLvlPhD}
                              </span>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm float-end"
                                onClick={() => handleDelete(item.eduId)}
                              >
                                <i className="bi bi-trash3"></i>
                              </button>
                              <button
                                type="button"
                                className="btn me-2 btn-success button btn-sm float-end"
                                onClick={() => {
                                  handleEdit(item);
                                  setIsAddUpdateBtnDisabled(false);
                                }}
                              >
                                <i className="bi bi-pencil"></i>
                              </button>
                            </div>
                            <div className="card-body">
                              <div className="card-subtitle mb-2 ">
                                <div className="row">
                                  <div className="col-12 col-md-3 fw-medium">{lang[appLanguage].eduMajor}:</div>
                                  <div className="col-12 col-md-3 border-end no-border-end-mobile">{item.major}</div>
                                  <div className="col-12 col-md-3 fw-medium">{lang[appLanguage].eduGPA}:</div>
                                  <div className="col-12 col-md-3">{item.gradeGPA}</div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-3 fw-medium">{lang[appLanguage].eduCountry}:</div>
                                  <div className="col-12 col-md-3 border-end no-border-end-mobile">{item.country}</div>
                                  <div className="col-12 col-md-3 fw-medium">{lang[appLanguage].eduStdyLang}:</div>
                                  <div className="col-12 col-md-3">{item.languageOfStudy}</div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer text-body-secondary fw-medium">
                              {lang[appLanguage].eduGradYear}:&nbsp;
                              {item.graduationYear}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </div>
        </form>
      )}
      <div className="row">
        <div className="col-12 col-md-6 mt-2">
          <button type="button" className="btn btn-success button me-1" onClick={prevStep}>
            {lang[appLanguage].btnBack}
          </button>
          <button
            type="button"
            className={`btn ${educationHistory.length === 0 ? "btn-dark" : "btn-success"} button`}
            onClick={() => {
              setLoading(true);
              handleSaveAndContinue();
            }}
            disabled={hasEducationHistory && educationHistory.length === 0}
          >
            {loading ? (
              <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="status px-1" role="status">
                  {lang[appLanguage].loading}
                </span>
              </>
            ) : (
              lang[appLanguage].btnSaveCont
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

Education.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  educationHistory: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
};

export default Education;
