/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { db, auth } from "../../firebase";
import { arrayUnion, collection, doc, getDocs, query, addDoc, updateDoc, where } from "firebase/firestore/lite";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { refreshAccessToken } from "../../Auth/tokenManager";
import { Modal, Button, Spinner } from "react-bootstrap"; // Import Modal, Button, and Spinner from react-bootstrap
import { LanguageContext } from "../../context/LanguageContext";
import lang from "../../components/translations";

const Summary = ({ prevStep, data }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { appLanguage } = useContext(LanguageContext);
  const [, setSubmitting] = useState(false); // New state for handling submission loading
  const navigateUrl = useNavigate();

  const handleShowModal = (url) => {
    setModalContent(url);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent("");
  };

  const handleSubmitClick = async () => {
    setSubmitLoading(true);
    try {
      await handleSubmit();
    } finally {
      setSubmitLoading(false);
    }
  };

  const logErrorToFirebase = async (error, contextMessage, log) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
        const querySnapshot = await getDocs(personalInfoQuery);

        if (!querySnapshot.empty) {
          const personalInfoDoc = querySnapshot.docs[0];
          const errorDetails = {
            context: contextMessage,
            errorObject: serializeError(error),
            timestamp: new Date(),
            log: log,
          };

          await addDoc(collection(db, "personalInfo", personalInfoDoc.id, "error"), errorDetails);
        }
      }
    } catch (logError) {
      console.error("Error logging to Firebase:", logError);
    }
  };

  const serializeError = (error) => {
    if (error.response && error.response.data && Array.isArray(error.response.data)) {
      const errorData = error.response.data;
      return errorData;
    } else {
      return {
        message: error.toString(),
      };
    }
  };

  const prepareDataForSalesforce = () => {
    const salesforceData = {
      personalInfo: {
        fullName: data.personalInfo.fullName,
        email: data.personalInfo.email,
        gender: data.personalInfo.gender,
        dob: data.personalInfo.dob,
        VacanciesId: localStorage.getItem("id"),
        placeOfBirth: data.personalInfo.placeOfBirth,
        nationality: data.personalInfo.nationality,
        maritalStatus: data.personalInfo.maritalStatus,
        numberOfChildren: data.personalInfo.numberOfChildren,
        religion: data.personalInfo.religion,
        currentAddress: data.personalInfo.currentAddress,
        phoneNumber: data.personalInfo.phoneNumber,
        cvURL: data.personalInfo.cvURL,
        linkedinURL: data.personalInfo.linkedinURL,
      },
      educationHistory: data.educationHistory.map((edu) => ({
        institution: edu.name,
        graduationYear: edu.graduationYear,
        certificateLevel: edu.certificateLevel,
        major: edu.major,
        gradeGPA: edu.gradeGPA,
        country: edu.country,
        languageOfStudy: edu.languageOfStudy,
      })),
      experienceHistory: data.experienceHistory.map((exp) => ({
        positionName: exp.positionName,
        companyName: exp.companyName,
        startingSalary: exp.startingSalary,
        endingSalary: exp.endingSalary,
        currency: exp.currency,
        companyAddress: exp.companyAddress,
        startDate: exp.startDate,
        endDate: exp.stillWorking ? null : exp.endDate,
        fieldOfWork: exp.fieldOfWork,
        typeOfJob: exp.typeOfJob,
        managerName: exp.managerName,
        managerNumber: exp.managerNumber,
        managerTitle: exp.managerTitle,
        isReferenceCallConfirmed: exp.isReferenceCallConfirmed,
        performanceRating: exp.performanceRating,
        mostLiked: exp.mostLiked,
        leastLiked: exp.leastLiked,
        leaveReason: exp.leaveReason,
        leaveExplanation: exp.leaveExplanation,
      })),
      languageSkills: data.languageSkills.map((lang) => ({
        name: lang.name,
        reading: lang.reading,
        writing: lang.writing,
        speaking: lang.speaking,
        listening: lang.listening,
      })),
      attachments: data.attachments,
    };

    return salesforceData;
  };

  const handleSubmit = async () => {
    const id = localStorage.getItem("id");

    if (id == null) {
      showToast(lang[appLanguage].errorTitle, lang[appLanguage].errorIdNotFound);
      return;
    }

    const salesforceData = prepareDataForSalesforce();
    let accessToken;
    setSubmitting(true); // Set submitting state to true
    let candidateId = null;

    try {
      accessToken = await refreshAccessToken();
    } catch (error) {
      showToast(lang[appLanguage].errorTitle, lang[appLanguage].errorAccessToken);
      return;
    }

    let log = [];

    try {
      log.push("Starting candidate creation process");
      // First, try to create the candidate in Salesforce
      const response = await axios.post(
        "https://saaeda.my.salesforce.com/services/data/v61.0/sobjects/Candidates__c",
        {
          Vacancy__c: localStorage.getItem("id"),
          Candidate_Name__c: salesforceData.personalInfo.fullName,
          Status__c: "New",
          cvURL__c: salesforceData.personalInfo.cvURL,
          Phone__c: salesforceData.personalInfo.phoneNumber,
          Gender__c: salesforceData.personalInfo.gender,
          Marital_Status__c: salesforceData.personalInfo.maritalStatus,
          Nationality__c: salesforceData.personalInfo.nationality,
          Date_Of_Birthday__c: salesforceData.personalInfo.dob,
          Current_Address__c: salesforceData.personalInfo.currentAddress,
          Number_Of_Children__c: salesforceData.personalInfo.numberOfChildren,
          Place_Of_Birth__c: salesforceData.personalInfo.placeOfBirth,
          Religion__c: salesforceData.personalInfo.religion,
          Email__c: salesforceData.personalInfo.email,
          Candidate_Link__c: salesforceData.personalInfo.linkedinURL,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Get the candidate ID from the response
      candidateId = response.data.id;
      // Sequentially add data to Salesforce
      log.push("Submitting education history");
      await submitEducationHistory(salesforceData.educationHistory, candidateId, accessToken);
      log.push("Submitting experience history");
      await submitExperienceHistory(salesforceData.experienceHistory, candidateId, accessToken);
      log.push("Submitting language skills");
      await submitLanguageSkills(salesforceData.languageSkills, candidateId, accessToken);
      // Update the `Vacancy_Array` fields in Firestore only if everything is successful
      const personalInfoDocRef = doc(db, "personalInfo", data.personalInfo.id);

      const vacancyId = localStorage.getItem("id");

      const finalVacancyId = vacancyId && vacancyId.length === 18 ? vacancyId.slice(0, -3) : vacancyId;

      await updateDoc(personalInfoDocRef, {
        Vacancy_Array: arrayUnion(finalVacancyId),
      });

      log.push("Submission successful");
      showToast(lang[appLanguage].successTitle, lang[appLanguage].successSubmittion);
      navigateUrl(`/?id=${id}`);
      window.scrollTo(0, 0);
    } catch (error) {
      showToast(lang[appLanguage].errorTitle, lang[appLanguage].errorSubmittion);
      // Log the error and the accumulated log to Firebase
      await logErrorToFirebase(error, "Error submitting data", log);

      // Rollback if any error occurs after candidate creation
      if (candidateId) {
        try {
          await axios.delete(
            `https://saaeda.my.salesforce.com/services/data/v61.0/sobjects/Candidates__c/${candidateId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
        } catch (rollbackError) {
          log = [];
          await logErrorToFirebase(rollbackError, "Error rolling back candidate creation", log);
        }
      }
    } finally {
      setSubmitting(false); // Set submitting state to false
    }
  };

  const submitEducationHistory = async (educationHistory, candidateId, accessToken) => {
    for (const edu of educationHistory) {
      await axios.post(
        "https://saaeda.my.salesforce.com/services/data/v61.0/sobjects/Educations__c",
        {
          Candidates__c: candidateId,
          Certificate_Level__c: edu.certificateLevel,
          Country__c: edu.country,
          Grade_GPA__c: edu.gradeGPA,
          Graduation_Date__c: edu.graduationYear,
          Language_Of_Study__c: edu.languageOfStudy,
          Major__c: edu.major,
          School_or_University_Name__c: edu.institution,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
  };

  const submitExperienceHistory = async (experienceHistory, candidateId, accessToken) => {
    for (const exp of experienceHistory) {
      await axios.post(
        "https://saaeda.my.salesforce.com/services/data/v61.0/sobjects/Experiences__c",
        {
          Candidates__c: candidateId,
          canArrangeCall__c: exp.isReferenceCallConfirmed,
          Company_Address__c: exp.companyAddress,
          Company_Name__c: exp.companyName,
          End_Date__c: exp.endDate,
          Start_Date__c: exp.startDate,
          Ending_Base_Salary__c: exp.endingSalary,
          Starting_Base_Salary__c: exp.startingSalary,
          Explanation_for_Reason_s_for_Leaving__c: exp.leaveExplanation,
          Field_of_Work__c: exp.fieldOfWork,
          Employee_Evaluation__c: exp.performanceRating,
          Manager_Name__c: exp.managerName,
          Manager_Phone_Number__c: exp.managerNumber,
          Manager_Title__c: exp.managerTitle,
          Position_Title__c: exp.positionName,
          Reason_for_Leaving__c: exp.leaveReason,
          Type_of_Job__c: exp.typeOfJob,
          What_least_enjoy_job__c: exp.leastLiked,
          What_most_enjoy_job__c: exp.mostLiked,
          CurrencyIsoCode: "USD",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
  };

  const submitLanguageSkills = async (languageSkills, candidateId, accessToken) => {
    for (const lang of languageSkills) {
      await axios.post(
        "https://saaeda.my.salesforce.com/services/data/v61.0/sobjects/Candidate_Language_Skills__c",
        {
          Candidate__c: candidateId,
          Name: lang.name,
          Reading__c: lang.reading,
          Writing__c: lang.writing,
          Speaking__c: lang.speaking,
          Listening__c: lang.listening,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
  };

  const showToast = (header, message) => {
    const toastElement = document.getElementById("liveToast");
    const toastHeader = toastElement.querySelector(".me-auto");
    const toastBody = toastElement.querySelector(".toast-body");
    toastHeader.textContent = header;
    toastBody.textContent = message;
    const toast = new window.bootstrap.Toast(toastElement, {
      delay: 5000,
    });
    toast.show();
  };

  return (
    <div className="container">
      <h4>{lang[appLanguage].smryTitle}</h4>
      <div className="row">
        <div className="col px-4">
          <h5>{lang[appLanguage].smryPiTitle}</h5>
          <ul className="list-group col shadow-sm">
            <li className="bg-light list-group-item">
              <div className="row">
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].smryPiFullname}:</strong>
                </div>
                <div className="border-end no-border-end-mobile col-12 col-md-3">{data.personalInfo.fullName}</div>
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piEmail}:</strong>
                </div>
                <div className="col-12 col-md-3">{data.personalInfo.email}</div>
              </div>
            </li>
            <li className="bg-light list-group-item">
              <div className="row">
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piGender}:</strong>
                </div>
                <div className="border-end no-border-end-mobile col-12 col-md-3">{data.personalInfo.gender}</div>
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piDoB}:</strong>
                </div>
                <div className="col-12 col-md-3">{data.personalInfo.dob}</div>
              </div>
            </li>
            <li className="bg-light list-group-item">
              <div className="row">
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piPoB}:</strong>
                </div>
                <div className="border-end no-border-end-mobile col-12 col-md-3">{data.personalInfo.placeOfBirth}</div>
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piNationality}:</strong>
                </div>
                <div className="col">{data.personalInfo.nationality}</div>
              </div>
            </li>
            <li className="bg-light list-group-item">
              <div className="row">
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piMStatus}:</strong>
                </div>
                <div className="border-end no-border-end-mobile col-12 col-md-3">{data.personalInfo.maritalStatus}</div>
                <div className="col-12 col-md-3">
                  <strong>{data.personalInfo.maritalStatus !== "Single" ? "Number of Children:" : ""}</strong>
                </div>
                <div className="col-12 col-md-3">
                  {data.personalInfo.maritalStatus !== "Single" ? data.personalInfo.numberOfChildren : ""}
                </div>
              </div>
            </li>
            <li className="bg-light list-group-item">
              <div className="row">
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piReligion}:</strong>
                </div>
                <div className="border-end no-border-end-mobile col-12 col-md-3">{data.personalInfo.religion}</div>
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].smryPiAddress}:</strong>
                </div>
                <div className="col-12 col-md-3">{data.personalInfo.currentAddress}</div>
              </div>
            </li>
            <li className="bg-light list-group-item">
              <div className="row">
                <div className="col-12 col-md-3">
                  <strong>{lang[appLanguage].piPhone}:</strong>
                </div>
                <div className="border-end no-border-end-mobile col-12 col-md-3">{data.personalInfo.phoneNumber}</div>
                <div className="col-12 col-md-3">
                  {data.personalInfo.linkedinURL ? <strong>{lang[appLanguage].piLinkedIn}:</strong> : ""}
                </div>
                <div className="col-12 col-md-3">
                  <a
                    href={data.personalInfo.linkedinURL ? data.personalInfo.linkedinURL : ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.personalInfo.linkedinURL ? data.personalInfo.linkedinURL : ""}
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-success px-4">
        <hr />
      </div>
      <div className="row">
        <div className="col px-4">
          <h5>{lang[appLanguage].smryEduTitle}</h5>
          <ul className="list-group col shadow-sm">
            {data.educationHistory.map((edu, index) => (
              <li className="list-group-item" key={index}>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduName}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{edu.name}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduGradYear}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{edu.graduationYear}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduCertLvl}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{edu.certificateLevel}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduMajor}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{edu.major}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduGPA}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{edu.gradeGPA}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduCountry}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{edu.country}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].eduStdyLang}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{edu.languageOfStudy}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="text-success px-4">
        <hr />
      </div>
      <div className="row">
        <div className="col px-4">
          <h5>{lang[appLanguage].smryExpTitle}</h5>
          <ul className="list-group col shadow-sm">
            {data.experienceHistory.map((exp, index) => (
              <li className="list-group-item" key={index}>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expPosTitle}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{exp.positionName}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expCpnyName}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{exp.companyName}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpSlryStrt}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">
                    {exp.startingSalary} {exp.currency}
                  </div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpSlryEnd}:</strong>
                  </div>
                  <div className="col-12 col-md-3">
                    {exp.endingSalary} {exp.currency}
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expCpnyAdrs}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{exp.companyAddress}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpStrtDate}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{exp.startDate}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpEndDate}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">
                    {exp.stillWorking ? "Currently Working" : exp.endDate}
                  </div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expWorkField}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{exp.fieldOfWork}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expWorkType}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{exp.typeOfJob}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expMngrName}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{exp.managerName}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expMngrPhon}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">{exp.managerNumber}</div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expMngrTitle}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{exp.managerTitle}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expArngCall}:</strong>
                  </div>
                  <div className="border-end no-border-end-mobile col-12 col-md-3">
                    {exp.isReferenceCallConfirmed ? lang[appLanguage].expOptionYes : lang[appLanguage].expOptionNo}
                  </div>
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpMngrRate}:</strong>
                  </div>
                  <div className="col-12 col-md-3">{exp.performanceRating}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpMostLike}:</strong>
                  </div>
                  <div className="col-12 col-md-9">{exp.mostLiked}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpLstEnjy}:</strong>
                  </div>
                  <div className="col-12 col-md-9">{exp.leastLiked}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].expLevRsn}:</strong>
                  </div>
                  <div className="col-12 col-md-9">{exp.leaveReason}</div>
                </div>
                <div className="row pb-2">
                  <div className="col-12 col-md-3">
                    <strong>{lang[appLanguage].smryExpLevExpln}:</strong>
                  </div>
                  <div className="col-12 col-md-9">{exp.leaveExplanation}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="text-success px-4">
        <hr />
      </div>
      <div className="row">
        <div className="col px-4">
          <h5>{lang[appLanguage].lsaLangTitle}</h5>
          <ul className="list-group col shadow-sm">
            <li className="list-group-item table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{lang[appLanguage].lsaLang}</th>
                    <th scope="col" className="text-center">
                      {lang[appLanguage].lsaLangRead}
                    </th>
                    <th scope="col" className="text-center">
                      {lang[appLanguage].lsaLangWrit}
                    </th>
                    <th scope="col" className="text-center">
                      {lang[appLanguage].lsaLangSpek}
                    </th>
                    <th scope="col" className="text-center">
                      {lang[appLanguage].lsaLangLstn}
                    </th>
                  </tr>
                </thead>
                <thead>
                  {data.languageSkills.map((lang, index) => (
                    <tr key={index}>
                      <td scope="row">{lang.name}</td>
                      <td className="text-center">{lang.reading}</td>
                      <td className="text-center">{lang.writing}</td>
                      <td className="text-center">{lang.speaking}</td>
                      <td className="text-center">{lang.listening}</td>
                    </tr>
                  ))}
                </thead>
              </table>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-success px-4">
        <hr />
      </div>
      <div className="row">
        <div className="col px-4 ">
          <h5>{lang[appLanguage].smryAtchTitle}</h5>
          <div className="row py-2">
            <div className="col">
              {data.personalInfo.cvURL && (
                <button
                  onClick={() => handleShowModal(data.personalInfo.cvURL)}
                  type="button"
                  className="btn btn-warning"
                >
                  {lang[appLanguage].btnViewCV}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 col-md-6 mt-2">
          <button type="button" className="btn btn-success button me-1" onClick={prevStep}>
            {lang[appLanguage].btnBack}
          </button>
          <button type="button" className="btn btn-success button" onClick={handleSubmitClick} disabled={submitLoading}>
            {submitLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                {lang[appLanguage].loading}
              </>
            ) : (
              lang[appLanguage].btnSubmit
            )}
          </button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>Document Viewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={modalContent} style={{ width: "100%", height: "500px" }}></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleCloseModal}>
            {lang[appLanguage].btnClose}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Summary.propTypes = {
  prevStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default Summary;
