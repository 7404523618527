import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import Root from "./Root";
import { LanguageProvider } from "./context/LanguageContext"; // Import LanguageProvider

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <LanguageProvider>
    <React.StrictMode>
      <Root />
    </React.StrictMode>
  </LanguageProvider>
);
