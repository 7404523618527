import React, { useState, useEffect, useContext } from "react";
import PersonalInfo from "./Steps/PersonalInfo";
import Education from "./Steps/Education";
import Experience from "./Steps/Experience";
import Summary from "./Steps/Summary";
import "./Wizard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { collection, getDocs, query, where, addDoc } from "firebase/firestore/lite";
import LanguageAndAttachment from "./Steps/LanguageAndAttachment";
import { LanguageContext } from "../context/LanguageContext";
import lang from "../components/translations";

const Wizard = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    personalInfo: {},
    educationHistory: [],
    experienceHistory: [],
    languageSkills: [],
    attachments: [],
  });
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const location = useLocation();
  const { id, positionName } = location.state || {};
  const navigate = useNavigate();

  const { appLanguage } = useContext(LanguageContext);

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
        const querySnapshot = await getDocs(personalInfoQuery);

        if (!querySnapshot.empty) {
          const personalInfoDoc = querySnapshot.docs[0];
          const personalInfoData = personalInfoDoc.data();

          const educationSnapshot = await getDocs(
            collection(db, "personalInfo", personalInfoDoc.id, "educationHistory")
          );
          const educationData = educationSnapshot.docs.map((doc) => ({
            ...doc.data(),
            eduId: doc.id, // add a new id filed to identify
          }));

          const experienceSnapshot = await getDocs(
            collection(db, "personalInfo", personalInfoDoc.id, "experienceHistory")
          );
          const experienceData = experienceSnapshot.docs.map((doc) => ({
            ...doc.data(),
            expId: doc.id, // add a new id filed to identify
          }));

          const languageSkillsSnapshot = await getDocs(
            collection(db, "personalInfo", personalInfoDoc.id, "languageSkills")
          );
          const languageSkillsData = languageSkillsSnapshot.docs.map((doc) => ({
            ...doc.data(),
            lId: doc.id, // add a new id filed to identify
          }));

          const attachmentsSnapshot = await getDocs(collection(db, "personalInfo", personalInfoDoc.id, "attachments"));
          const attachmentsData = attachmentsSnapshot.docs.map((doc) => doc.data());

          setData({
            personalInfo: { ...personalInfoData, id: personalInfoDoc.id }, // Add document ID to the state
            educationHistory: educationData,
            experienceHistory: experienceData,
            languageSkills: languageSkillsData,
            attachments: attachmentsData,
          });
        } else {
          await addDoc(collection(db, "personalInfo"), {
            email: user.email,
          });
          await fetchData();
          return;
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkUserVerification = async () => {
      const user = auth.currentUser;
      if (user && user.emailVerified) {
        setIsVerified(true);
        await fetchData(); // Fetch data only if the user is verified
      } else {
        setIsVerified(false);
        navigate("/required-verify"); // Redirect to verify page if not verified
      }
    };

    checkUserVerification();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleDataChange = (newData, section) => {
    setData((prevState) => ({
      ...prevState,
      [section]: newData,
    }));
  };

  if (!isVerified) {
    return (
      <div className="card m-3 mx-auto rounded-4 shadow" style={{ width: "80rem" }}>
        <div className="card-body">
          <div className="alert alert-warning" role="alert">
            {lang[appLanguage].warnVerifyEmail}
          </div>
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <PersonalInfo nextStep={nextStep} personalInfo={data.personalInfo} handleDataChange={handleDataChange} />
        );
      case 2:
        return (
          <Education
            nextStep={nextStep}
            prevStep={prevStep}
            educationHistory={data.educationHistory || []}
            handleDataChange={handleDataChange}
          />
        );
      case 3:
        return (
          <Experience
            nextStep={nextStep}
            prevStep={prevStep}
            experienceHistory={data.experienceHistory || []}
            handleDataChange={handleDataChange}
          />
        );
      case 4:
        return (
          <LanguageAndAttachment
            nextStep={nextStep}
            prevStep={prevStep}
            personalInfo={data.personalInfo}
            languageSkills={data.languageSkills || []}
            handleDataChange={handleDataChange}
          />
        );
      case 5:
        return <Summary data={data} prevStep={prevStep} />;
      default:
        return (
          <PersonalInfo nextStep={nextStep} personalInfo={data.personalInfo} handleDataChange={handleDataChange} />
        );
    }
  };

  // In case of error or Id not found
  if (!id) {
    return (
      <div className="card m-3 mx-auto rounded-4 shadow" style={{ width: "80rem" }}>
        <div className="card-body">
          <div className="alert alert-danger" role="alert">
            {lang[appLanguage].errorLoadingJob}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="card m-3 mx-auto pb-3 rounded-4 shadow responsive-card-width"
      dir={appLanguage === "en" ? "ltr" : "rtl"}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="card-title fs-4">{lang[appLanguage].posApplying}</h4>
            <h6 className="card-subtitle mb-2 text-muted text-uppercase">{positionName}</h6>
          </div>

          <div className="col-12 col-md-6 text-end">
            <button className="btn btn-success button" onClick={() => (window.location.href = `/?id=${id}`)}>
              {lang[appLanguage].viewJobDesc}
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="container">
          <p className="text-muted">
            {lang[appLanguage].piDesc1}
            <br />
            {lang[appLanguage].piDesc2}
            <br />
            {lang[appLanguage].piDesc3}
          </p>
          <hr />
          <div className="mb-4 mx-0 navbar rounded-2 row shadow">
            <div className="col-12 col-md-6">
              <h4>
                <i className="bi bi-person-vcard-fill"></i> {lang[appLanguage].piTitle}
              </h4>
            </div>
          </div>
          <form>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piEmail} </label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piFullname} </label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piGender}</label>
                  <select className="form-select placeholder" disabled>
                    <option value=""></option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piDoB} </label>
                  <input type="date" className="form-control placeholder" disabled />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piPoB} </label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piMStatus} </label>
                  <select className="form-select placeholder" disabled>
                    <option value=""></option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piChildNum} </label>
                  <input type="number" className="form-control placeholder" disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piNationality} </label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piReligion} </label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piAddress}</label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piPhone} </label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-6">
                <div className="mb-3 placeholder-glow">
                  <label className="form-label">{lang[appLanguage].piLinkedIn}</label>
                  <input type="text" className="form-control placeholder" disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <button className="btn btn-success placeholder col-4" disabled>
                  &nbsp;
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        renderStep()
      )}
    </div>
  );
};

export default Wizard;
