/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./JobDescription.css";
import { getAccessToken, refreshAccessToken } from "../../Auth/tokenManager";
import { auth, db } from "../../firebase";
import AuthModal from "../../Auth/AuthModal/AuthModal";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import ConfettiExplosion from "react-confetti-explosion";
import { LanguageContext } from "../../context/LanguageContext";
import lang from "../translations";

const JobDescription = () => {
  const [job, setJob] = useState(null);
  const [jobExpired, setJobExpired] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [positionName, setPositionName] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [fromApply, setFromApply] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false); // State to track if the user has already applied
  const [isExploding, setIsExploding] = useState(true);
  const { appLanguage } = useContext(LanguageContext);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  localStorage.setItem("id", id != null ? id : localStorage.getItem("id") != null ? localStorage.getItem("id") : null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        checkIfApplied(currentUser.email);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfApplied = async (userEmail) => {
    try {
      const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
      const querySnapshot = await getDocs(personalInfoQuery);
      const filteredDocs = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data.Vacancy_Array && (data.Vacancy_Array.includes(id) || data.Vacancy_Array.includes(id.slice(0, -3)))) {
          filteredDocs.push({ id: doc.id, ...data });
        }
      });

      if (filteredDocs.length !== 0) {
        setAlreadyApplied(true);
      }
    } catch (error) {
      console.error("Error checking application status: ", error);
    }
  };

  const fetchJobData = async (id, accessToken) => {
    const response = await axios.get(
      `https://saaeda.my.salesforce.com/services/data/v61.0/query/?q=SELECT+Id,Position_Name__c,Position_Name_AR__c,Job_Description__c,Job_Description_AR__c,Experience_Level__c,Job_Type__c,Skills_Competencies__c,Skills_Competencies_AR__c,Governorate__c,Department__c,Job_End_Date__c,Stage__c,About_Us__c,About_Us_AR__c+FROM+Vacancies__c+WHERE+Id='${id}'`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    const job = response.data.records[0];
    const positionName = job.Position_Name__c;

    return { job, positionName };
  };

  useEffect(() => {
    const fetchJobDescription = async () => {
      if (id) {
        try {
          let accessToken = await getAccessToken();

          const { job, positionName } = await fetchJobData(id, accessToken);
          setJob(job);
          setPositionName(positionName);

          const jobEndDate = job.Job_End_Date__c;

          // Parse the Salesforce date string to a JavaScript Date object
          const jobEndDateObj = new Date(jobEndDate);
          jobEndDateObj.setHours(0, 0, 0, 0);

          // Get today's date
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          // Show expired message if the date is less than today
          // Or stage is not "In-Progress"
          if (jobEndDateObj < today || job.Stage__c !== "In-Progress") {
            setJobExpired(true);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // If token expired, refresh and retry
            try {
              let accessToken = await refreshAccessToken();
              const { job, positionName } = await fetchJobData(id, accessToken);
              setJob(job);
              setPositionName(positionName);
            } catch (refreshError) {
              setError(refreshError);
            }
          } else {
            setError(error);
          }
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchJobDescription();
  }, [id, location]);

  useEffect(() => {
    if (isExploding && !loading && user && alreadyApplied) {
      const audio = new Audio(process.env.PUBLIC_URL + "/sounds/pop.mp3");
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  }, [isExploding, loading]);

  const handleApply = () => {
    if (user) {
      if (user.emailVerified) {
        navigate("/wizard", {
          state: { id: id, positionName: positionName },
        });
      } else {
        navigate("/verification", {
          state: { email: user.email },
        });
      }
    } else {
      setFromApply(true);
      setIsAuthModalOpen(true);
    }
  };

  if (loading) {
    return (
      <div
        className="card m-3 mx-auto rounded-4 shadow responsive-card-width"
        dir={appLanguage === "en" ? "ltr" : "rtl"}
      >
        <div className="card-body placeholder-glow">
          <h5 className="card-title fs-3 placeholder col-4"> </h5>
          <br />
          <h6 className="card-subtitle mb-2 text-muted placeholder col-2"> </h6>
          <div className="row mb-1 mx-2">
            <span className="placeholder col"></span>&nbsp;
            <span className="placeholder col"></span>&nbsp;
            <span className="placeholder col"></span>&nbsp;
            <span className="placeholder col"></span>
          </div>
          <div className="row mb-1 mx-2">
            <span className="placeholder col"></span>&nbsp;
            <span className="placeholder col"></span>&nbsp;
            <span className="placeholder col"></span>&nbsp;
            <span className="placeholder col"></span>
          </div>
          <div className="row mb-1 mx-2">
            <span className="placeholder col-3"></span>&nbsp;
            <span className="placeholder col-3"></span>
          </div>

          <h2 className="placeholder col-3 mt-3"> </h2>
          <p className="placeholder col-12 p-5"></p>

          <h2 className="placeholder col-3"> </h2>
          <p className="placeholder col-12 p-5"></p>

          <h2 className="placeholder col-3"> </h2>
          <p className="placeholder col-12 p-5"></p>

          <p className="placeholder col-9 p-3"></p>

          <br />
          <p className="btn btn-dark disabled placeholder col-2" aria-disabled="true"></p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
        <div className="card-body">
          <div className="alert alert-danger" role="alert">
            {lang[appLanguage].errorLoadingJob}
          </div>
        </div>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
        <div className="card-body">
          <div className="alert alert-warning" role="alert">
            {lang[appLanguage].warnNoJob}
          </div>
        </div>
      </div>
    );
  }

  if (jobExpired) {
    return (
      <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width">
        <div className="card-body">
          <div className="alert alert-warning" role="alert">
            {lang[appLanguage].warnOfferExpired}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card m-3 mx-auto rounded-4 shadow responsive-card-width" dir={appLanguage === "en" ? "ltr" : "rtl"}>
      {alreadyApplied && (
        <div className="alert alert-success m-4 mb-1" role="alert">
          🎉 {lang[appLanguage].congrats} 🚀🌟
          {isExploding && (
            <div className="confetti-container">
              <ConfettiExplosion
                onComplete={() => setIsExploding(false)}
                force={0.6}
                duration={3000}
                particleCount={200}
                width={1600}
              />
            </div>
          )}
        </div>
      )}

      <div className="card-body">
        <h5 className="card-title fs-3">{job.Position_Name__c}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{job.Job_Type__c}</h6>
        <div className="card my-3 p-2 shadow-sm">
          <div className="row">
            <div className="col-12 col-md-3 fw-bold">{lang[appLanguage].jdPostion}</div>
            <div className="col-12 col-md-3">
              {appLanguage === "en" ? job.Position_Name__c : job.Position_Name_AR__c}
            </div>
            <div className="col-12 col-md-3 fw-bold">{lang[appLanguage].jdExpLevel}</div>
            <div className="col-12 col-md-3">{job.Experience_Level__c}</div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3 fw-bold">{lang[appLanguage].jdDept}</div>
            <div className="col-12 col-md-3">{job.Department__c}</div>
            <div className="col-12 col-md-3 fw-bold">{lang[appLanguage].jdLocation}</div>
            <div className="col-12 col-md-3">{job.Governorate__c}</div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3 fw-bold">{lang[appLanguage].jdWorkType}</div>
            <div className="col-12 col-md-3">{job.Job_Type__c}</div>
          </div>
        </div>

        <div className="card my-3 p-2 shadow-sm">
          <div
            dangerouslySetInnerHTML={{
              __html: appLanguage === "en" ? job.Job_Description__c : job.Job_Description_AR__c,
            }}
          />
        </div>

        <div className="card my-3 p-2 shadow-sm">
          <div
            dangerouslySetInnerHTML={{
              __html: appLanguage === "en" ? job.Skills_Competencies__c : job.Skills_Competencies_AR__c,
            }}
          />
        </div>

        <div className="card my-3 p-2 shadow-sm">
          <div dangerouslySetInnerHTML={{ __html: appLanguage === "en" ? job.About_Us__c : job.About_Us_AR__c }} />
        </div>

        {alreadyApplied ? (
          <p className="text-success">{lang[appLanguage].warnAlreadyAplied}</p>
        ) : (
          <button type="button" className="btn btn-success button px-5" onClick={handleApply}>
            {lang[appLanguage].btnApply}
          </button>
        )}
      </div>
      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={() => setIsAuthModalOpen(false)}
        fromApply={fromApply}
        id={id}
        positionName={positionName}
      />
    </div>
  );
};

export default JobDescription;
